import React, { Component } from 'react'
import { TextField, Button, CircularProgress, Typography } from '@material-ui/core'
import withTranslation from '../../services/withTranslation'
import {InputLabelProps} from '../../styles/muiProps'
import user from '../../assets/user.png'
import key from '../../assets/key.png'
import {Enter} from '../../constants/keyCode'


class ResetPassword extends Component {
  componentDidMount() {
    const { code } = this.props.match.params
    !!code && this.props.changeField({ field: 'code', value: code })
  }

  onSubmit = () => {
    const { t, code, password, submit, validateFields } = this.props
    if (code.length < 1) {
      validateFields({ field: 'code', errorText: t('fieldRequired') })
    } else if (password.length < 1) {
      validateFields({ field: 'password', errorText: t('fieldRequired') })
    } else {
      submit()
    }
  }

  renderButton = () => {
    const { t, success, redirect_uri } = this.props
    if (!success) {
      return (
        <button className="send-btn" onClick={this.onSubmit}>
          {t('submitButton')}
        </button>
      )
    } else if (success && !!redirect_uri) {
      return (
        <Button variant="contained" color="primary" size="large" onClick={() => this.props.history.push('/')}>
          {t('nextButton')}
        </Button>
      )
    } else if (success && !redirect_uri) {
      return <Typography color="primary">{t('reloginMessage')}</Typography>
    }
  }

  render() {
    const { t, code, password, error, inProgress, changeField, errorText, validate, success } = this.props
    return (
      <div className='card'>
        <Typography style={{marginTop: '50px', color: '#fff'}}>{!success ? t('verifyText') : t('passwordSuccessMessage')}</Typography>

        {!success && (
          <React.Fragment>
            <div className="email-container">
              <TextField
                className="email"
                label={t('verifyInput')}
                value={code}
                InputLabelProps={InputLabelProps}
                onChange={(e) => changeField({ field: 'code', value: e.target.value })}
                required={true}
                error={validate.field === 'code'}
                helperText={validate.field === 'code' && validate.errorText}
                onKeyPress={(e) => e.key === Enter && this.onSubmit()}
                type="number"
              />
              <div className="user">
                <img src={user} />
              </div>
            </div>

            <div className="key-container">
              <TextField
                label={t('newPasswordInput')}
                value={password}
                className="password"
                InputLabelProps={InputLabelProps}
                onChange={(e) => changeField({ field: 'password', value: e.target.value })}
                type="password"
                required={true}
                error={validate.field === 'password'}
                helperText={validate.field === 'password' && validate.errorText}
                onKeyPress={(e) => e.key === Enter && this.onSubmit()}
              />
              <div className="key">
                <img src={key} />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="button-container">{inProgress ? <CircularProgress size={40} /> : this.renderButton()}</div>
        {error && <Typography color="error">{errorText ? errorText : t('generalError')}</Typography>}
      </div>
    )
  }
}

export default withTranslation(ResetPassword)
