import React, { Component } from 'react'
import { TextField, Button, CircularProgress, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import withTranslation from '../../services/withTranslation'
import user from '../../assets/user.png'
import {InputLabelProps} from '../../styles/muiProps'
import {Enter} from '../../constants/keyCode'



class EmailForm extends Component {
  async componentDidMount() {
    const { path, params } = this.props.match
    const { setVariant, changeField } = this.props
    path.includes('verify') ? await setVariant('verify') : await setVariant('password')
    if (!!params.username) {
      await changeField(params.username)
      this.onSubmit()
    }
  }

  onSubmit = () => {
    const { t, username, submit, validateFields } = this.props
    if (username.length < 1) {
      validateFields({ field: 'username', errorText: t('fieldRequired') })
    } else {
      submit()
    }
  }
  render() {
    const { t, username, error, inProgress, changeField, errorText, validateError, validateText, success, variant } =
      this.props
    return (
      <div className="card">
        <div className="email-container">
          <TextField
            className="email"
            label="email"
            value={username}
            InputLabelProps={InputLabelProps}
            onChange={(e) => changeField(e.target.value)}
            required={true}
            error={validateError}
            helperText={validateError && validateText}
            onKeyPress={(e) => e.key === Enter && this.onSubmit()}
          />
          <div className="user">
            <img src={user} />
          </div>
        </div>

        <div className="button-container">
          {inProgress ? (
            <CircularProgress size={40} />
          ) : (
            <button className="send-btn" onClick={this.onSubmit}>
              Send
            </button>
          )}
        </div>
        {error && <Typography color="error">{errorText ? errorText : t('generalError')}</Typography>}
        {success && <Redirect to={variant === 'verify' ? '/verify' : '/password/reset'} />}
      </div>
    )
  }
}

export default withTranslation(EmailForm)
