import React, { Component } from 'react'
import { TextField, Button, CircularProgress, Link, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import withTranslation from '../../services/withTranslation'
import user from '../../assets/user.png'
import key from '../../assets/key.png'
import {InputLabelProps} from '../../styles/muiProps'
import photo from '../../assets/photo.webp'
import {Enter} from '../../constants/keyCode'




class Signin extends Component {
  componentWillUnmount() {
    this.props.cleanup()
  }

  onSubmit = () => {
    const { t, username, password, submit, validateFields } = this.props
    if (username.length < 1) {
      validateFields({ field: 'username', errorText: t('fieldRequired') })
    } else if (password.length < 1) {
      validateFields({ field: 'password', errorText: t('fieldRequired') })
    } else {
      submit()
    }
  }
 
  render() {
    const { t, username, password, error, inProgress, changeField, errorText, validate } = this.props
    return (
      <div className="login">
        <div className="card">
          <div className="email-container">
            <TextField
              label={'Email Address'}
              className="email"
              InputLabelProps={InputLabelProps}
              value={username}
              onChange={(e) => changeField({ field: 'username', value: e.target.value })}
              required={true}
              error={validate.field === 'username'}
              helperText={validate.field === 'username' && validate.errorText}
              onKeyPress={(e) => e.key === Enter && this.onSubmit()}
            />
            <div className="user">
              <img src={user} />
            </div>
          </div>
          <div className="key-container">
            <TextField
              label="Password"
              value={password}
              InputLabelProps={InputLabelProps}
              onChange={(e) => changeField({ field: 'password', value: e.target.value })}
              className="password"
              type="password"
              required={true}
              error={validate.field === 'password'}
              helperText={validate.field === 'password' && validate.errorText}
              onKeyPress={(e) => e.key === Enter && this.onSubmit()}
            />
            <div className="key">
              <img src={key} />
            </div>
          </div>

          <div style={{margin: '86px 0 0' }} className="button-container">
            {inProgress ? (
              <CircularProgress size={40} />
            ) : (
              <button className="send-btn" onClick={this.onSubmit}>
                Login
              </button>
            )}
          </div>
          {error && <Typography color="error">{errorText ? errorText : t('generalError')}</Typography>}
          <div className="reset-password">
            <Link style={{ color: 'rgb(194, 194, 194)' }} component={RouterLink} to="/password/request">
              Forget Password?
            </Link>
          </div>
          {/*<div className="registration">*/}
          {/*  <Link style={{ color: 'rgb(194, 194, 194)' }} component={RouterLink} to="/re-verify">*/}
          {/*    Don't have an account?*/}
          {/*  </Link>*/}
          {/*</div>*/}

          <div className="separator" />
        </div>
        <img className="photo" src={photo} />
      </div>
    )
  }
}

export default withTranslation(Signin)
